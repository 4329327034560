import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";

import trustedIcon from "../../../images/v4/logos/trustedIcon.svg";
import Goodfirms from "../../../images/v4/whatsappPage/images/Goodfirms.png";
import Sourceforge from "../../../images/v4/whatsappPage/images/Sourceforge.png";
import Slashdot from "../../../images/v4/whatsappPage/images/Slashdot.png";

const TruestedSection = () => {
  const list = [
    <a
      href="https://www.capterra.com/p/10007145/Pepper-Cloud-CRM/reviews/"
      rel="nofollow"
    >
      {" "}
      <img
        border="0"
        alt="Caterra Badge - Pepper Cloud CRM"
        src="https://brand-assets.capterra.com/badge/5b57f708-a0f9-442c-b531-2917309501e1.svg"
      />{" "}
    </a>,
    <a
      href="https://www.softwaresuggest.com/pepper-cloud"
      target="_blank"
      rel="nofollow"
    >
      <img
        alt="softwaresuggest Badge - Pepper Cloud CRM"
        src="https://www.softwaresuggest.com/award_logo/easy-usability-2024.png"
      />
    </a>,
    <a
      href="https://www.getapp.com/customer-management-software/a/pepper-cloud-crm/reviews/"
      target="_blank"
      rel="nofollow"
    >
      {" "}
      <img
        border="0"
        alt="getapp Badge - Pepper Cloud CRM"
        src="https://brand-assets.getapp.com/badge/d12d978c-0779-4f6a-9e95-f879fc998871.png"
      />{" "}
    </a>,
    <a
      href="https://sourceforge.net/software/product/Pepper-Cloud/"
      target="_blank"
      rel="nofollow"
    >
      <img src={Sourceforge} alt="sourceforge - Pepper Cloud CRM" />
    </a>,
    <a
      href="https://www.softwareadvice.com/product/511683-Pepper-Cloud-CRM/reviews/"
      target="_blank"
      rel="nofollow"
    >
      {" "}
      <img
        border="0"
        alt="softwareadvice Badge - Pepper Cloud CRM"
        src="https://brand-assets.softwareadvice.com/badge/a78bed71-c2ab-4fa8-af29-126d997b0a48.png"
      />{" "}
    </a>,
    <a
      href="https://www.producthunt.com/products/pepper-cloud-crm/reviews?utm_source=badge-product_rating&utm_medium=badge&utm_souce=badge-pepper&#0045;cloud&#0045;crm"
      target="_blank"
      rel="nofollow"
    >
      <img
        src="https://api.producthunt.com/widgets/embed-image/v1/product_rating.svg?product_id=590706&theme=light"
        alt="Product Hunt Badge - Pepper Cloud CRM"
      />
    </a>,
    <a
      href="https://www.goodfirms.co/software/pepper-cloud"
      target="_blank"
      rel="nofollow"
    >
      <img alt="good firms - Pepper Cloud CRM" src={Goodfirms} />
    </a>,
    <a
      href="https://crozdesk.com/customer-service-crm/crm-software/pepper-cloud-crm"
      target="_blank"
      rel="nofollow"
      title="Pepper Cloud CRM - software ratings and reviews on Crozdesk"
    >
      <img
        src="//embed.crozdesk.com/badges/v2/rating/pepper-cloud-crm"
        alt="crozdesk Badge - Pepper Cloud CRM"
      />
    </a>,
    <a
      href="https://www.trustradius.com/products/pepper-cloud-crm/reviews?source=ratings_badge&utm_source=badge&utm_medium=referral&utm_campaign=trustradius_ratings_badge"
      style="display:inline-block;"
      target="_blank"
      rel="nofollow"
      title="Read Pepper Cloud reviews on TrustRadius"
    >
      <img
        alt="TrustRadius Badge - Pepper Cloud CRM"
        src="https://www.trustradius.com/api/v1/ratings_badge/pepper-cloud-crm"
      />
    </a>,
    <a
      href="https://slashdot.org/software/p/Pepper-Cloud/"
      target="_blank"
      rel="nofollow"
    >
      <img src={Slashdot} alt="slashdot - Pepper Cloud CRM" />
    </a>,
  ];
  return (
    <section>
      <div className="container">
        <div className="trustedContainer">
          <div className="trustedContainerHeader">
            <h2 className="font-48 font-wix-semibold color-black1">
              <img src={trustedIcon} alt="trusted CRM" /> Trusted by businesses,
              loved by customers
            </h2>
          </div>
          <Swiper
            className={"trustedContainerBody"}
            slidesPerView="auto"
          spaceBetween={100}
          loop={true}
          speed={2000}
          modules={[Autoplay]}
          allowTouchMove={false}
          autoplay={{
            delay: 100,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
            breakpoints={{
              // For smaller screens (mobile)
              0: {
                freeMode: true,
                grabCursor: false,
                allowTouchMove: true,
                spaceBetween: 20,
              },
              // For larger screens (desktop)
              768: {
                freeMode: false,
                autoplay: {
                  delay: 100,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                },
                speed: 2000,
                loop: true,
                allowTouchMove: true,
              },
            }}
          >
            {list.map((listItem) => (
              <SwiperSlide className="trustedSlide">{listItem}</SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default TruestedSection;
