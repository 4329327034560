import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import testimonialHolder1 from "../../../images/v4/testimonials/testimonialHolder1.png";
import testimonialHolder2 from "../../../images/v4/testimonials/testimonialHolder2.png";
import testimonialHolder3 from "../../../images/v4/testimonials/testimonialHolder3.png";
import testimonialHolder4 from "../../../images/v4/testimonials/testimonialHolder4.png";
import testimonialHolder5 from "../../../images/v4/testimonials/testimonialHolder5.png";
import testimonialHolder6 from "../../../images/v4/testimonials/testimonialHolder6.png";
import testimonialHolder7 from "../../../images/v4/testimonials/testimonialHolder7.png";

import testimonialCompanyLogo1 from "../../../images/v4/testimonials/testimonialCompanyLogo1.svg";
import testimonialCompanyLogo2 from "../../../images/v4/testimonials/testimonialCompanyLogo2.svg";
import testimonialCompanyLogo3 from "../../../images/v4/testimonials/testimonialCompanyLogo3.svg";
import testimonialCompanyLogo4 from "../../../images/v4/testimonials/testimonialCompanyLogo4.svg";
import testimonialCompanyLogo5 from "../../../images/v4/testimonials/testimonialCompanyLogo5.svg";
import testimonialCompanyLogo6 from "../../../images/v4/testimonials/testimonialCompanyLogo6.svg";
import testimonialCompanyLogo7 from "../../../images/v4/testimonials/testimonialCompanyLogo7.svg";

const useStyles = makeStyles((theme) => ({
  testimonialSection: {},
  testimonialContainer: {
    width: "100%",
    overflow: "hidden",
    padding: "10px 0 !important",
    paddingBottom: "80px !important",
    "& .swiper-wrapper": {
      transitionTimingFunction: "linear",
    },
    "@media only screen and (max-width: 600px)": {
      paddingBottom: "40px",
      minHeight: "430px",
    },
  },
  testimonialCard: {
    maxWidth: "590px",
    display: "flex !important",
    overflow: "hidden",
    maxHeight: "320px",
    boxShadow: "0px 0px 20px #E3E2FD",
    alignItems: "stretch",
    borderRadius: "8px",
    justifyContent: "flex-start",
    flexShrink: "0",
    height: "auto",
    "@media only screen and (max-width: 768px)": {
      maxWidth: "300px",
      maxHeight: "max-content",
      minHeight: "630px",
      flexDirection: "column",
      height: "630px",
    },
  },
  testimonialUserImg: {
    width: "100%",
    height: "100%",
    "@media only screen and (max-width: 768px)": {
      width: "100%",
      height: "300px",
      objectFit: "cover",
    },
  },
  testimonialContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    background: "#fff",
    gap: "12px",
    padding: "20px",
    "@media only screen and (max-width: 768px)": {
      padding: "16px",
    },
  },
  testimonialCompanyLogo: {
    width: "auto",
    height: "auto",
  },
  testimonialCardFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "5px",
    marginTop: "15px",
  },
  imageContainer: {
    position: "relative",
    minWidth: "260px",
    "@media only screen and (max-width: 768px)": {
      minWidth: "100%",
    },
  },
  tag: {
    top: "10px",
    left: "10px",
    padding: "6px 12px",
    position: "absolute",
    background: "#fdfdfd69",
    backdropFilter: "blur(3px)",
    borderRadius: "50px",
  },
}));

const TestimonialSlider = () => {
  const classes = useStyles();
  const swiperRef = useRef(null);
  const [isInteracting, setIsInteracting] = useState(false);

  const testimonialData = [
    {
      tag: "Information technology",
      name: "Yoon Kam Fei",
      role: "Managing Director",
      image: testimonialHolder1,
      companyLogo: testimonialCompanyLogo1,
      alt: "CRM testimonial - Pepper Cloud + Tech Data",
      testimonial:
        "Team collaboration, accurate sales forecasting, and effective resource management are the challenges every organisation encounters. But having an efficient CRM tool like Pepper Cloud enhances productivity while assisting in optimising the sales process.",
    },
    {
      tag: "Information technology",
      name: "Pantana Na Takuathung",
      role: "General Manager",
      image: testimonialHolder2,
      companyLogo: testimonialCompanyLogo2,
      alt: "CRM testimonial - Pepper Cloud + SIS",
      testimonial:
        "Pepper Cloud is an excellent all-in-one CRM system to run multiple businesses across multiple domains. Through multiple sales pipelines, we track the business opportunities and the conversations between our team and our customers.",
    },
    {
      tag: "HR and recruitment",
      name: "Joel Toh",
      role: "Director",
      image: testimonialHolder3,
      companyLogo: testimonialCompanyLogo3,
      alt: "CRM testimonial - Pepper Cloud + the supereme hr advisory",
      testimonial:
        "Pepper Cloud is a sales-focused CRM that is helping our business to grow. The user interface is easy and our sales process is now more organised. Apart from the highly productive CRM features, we are happy with their exceptional support and relentless assistance",
    },
    {
      tag: "Information technology",
      name: "Judith M. Adao",
      role: "Managing Director",
      image: testimonialHolder4,
      companyLogo: testimonialCompanyLogo4,
      alt: "CRM testimonial - Pepper Cloud + Mustard Seed system corporation",
      testimonial:
        "A must-have for sales organisations. Pepper Cloud is a simple and easy-to-use CRM tool that equipped us with the information needed to manage the sales effectively. Their excellent customer service and local support were invaluable throughout the implementation.",
    },
    // {
    //   tag: "Photography",
    //   name: "Benny",
    //   role: "Creative Director",
    //   image: testimonialHolder5,
    //   companyLogo: testimonialCompanyLogo5,
    //   alt: "CRM testimonial - Pepper Cloud + firefly",
    //   testimonial:
    //     "Incorporating Pepper Cloud CRM for our business was smooth and hassle-free. The onboarding process was well-guided, and the training resources provided by their team facilitated a smooth transition for our employees to adapt to the new system.",
    // },
    {
      tag: "Finance",
      name: "Jimmy Lee",
      role: "Managing Director",
      image: testimonialHolder6,
      companyLogo: testimonialCompanyLogo6,
      alt: "CRM testimonial - Pepper Cloud + Cash Mart",
      testimonial:
        "Pepper Cloud CRM system helped us manage our leads and sales pipelines and provided us with a unified platform to collaborate and communicate internally. We are pleased with this solution and would definitely recommend it.",
    },
    {
      tag: "Maritime",
      name: "Wilson Tan",
      role: "Senior Manager",
      image: testimonialHolder7,
      companyLogo: testimonialCompanyLogo7,
      alt: "CRM testimonial - Pepper Cloud + SMF",
      testimonial:
        "Pepper Cloud is the best CRM tool to improve coordination and manage our database effectively. The user-friendly interface, easy-to-use functions, and customisable features enabled us to integrate, and onboard the whole team on the cloud platform quickly.",
    },
  ];

  const handleInteractionStart = () => {
    setIsInteracting(true);
    if (swiperRef.current && swiperRef.current.autoplay) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleInteractionEnd = () => {
    setIsInteracting(false);
    if (swiperRef.current && swiperRef.current.autoplay) {
      swiperRef.current.autoplay.start();
    }
  };

  return (
    <section className={classes.testimonialSection}>
      <div className="container">
        <div className="centerHeader">
          <h2 className="font-wix-medium font-56 color-black1">
            Our happy customers are accelerating their sales growth
          </h2>
          <p className="font-wix-regular font-18 color-black2">
            Many businesses trust Pepper Cloud CRM software to empower their
            sales team and run their operations globally. Know about our
            clients' experiences in their words.
          </p>
        </div>
      </div>
      <div className={classes.testimonialContainer}>
        <Swiper
          ref={swiperRef}
          slidesPerView="auto"
          spaceBetween={20}
          loop={true}
          speed={10000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          modules={[Autoplay]}
          allowTouchMove={false}
          className={classes.testimonialContainer}
        >
          {testimonialData.map((testimonialItem, index) => (
            <SwiperSlide
              onMouseEnter={handleInteractionStart}
              onMouseLeave={handleInteractionEnd}
              onTouchStart={handleInteractionStart}
              onTouchEnd={handleInteractionEnd}
              key={index}
              className={classes.testimonialCard}
            >
              <div className={classes.imageContainer}>
                <div
                  className={clsx(
                    classes.tag,
                    "font-12 font-wix-medium font-black7"
                  )}
                >
                  {testimonialItem.tag}
                </div>
                <img
                  src={testimonialItem.image}
                  alt={testimonialItem.alt}
                  className={classes.testimonialUserImg}
                />
              </div>
              <div className={classes.testimonialContent}>
                <img
                  src={testimonialItem.companyLogo}
                  alt={testimonialItem.alt}
                  className={classes.testimonialCompanyLogo}
                />
                <p className="font-wix-regular-italic font-14 color-black4">
                  {testimonialItem.testimonial}
                </p>
                <div className={classes.testimonialCardFooter}>
                  <p className="font-16 font-wix-semibold color-black1">
                    {testimonialItem.name}
                  </p>
                  <p className="font-14 font-wix-regular font-black4">
                    {testimonialItem.role}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="centerHeader">
          <motion.a
            href="/crm-testimonials"
            initial={{ opacity: 1, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="transparentLightButton font-wix-medium font-16"
          >
            Read client testimonials
          </motion.a>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;
